__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';





document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();

  const switches = document.querySelectorAll('.switch');
  switches.forEach((elem) => {
    elem.addEventListener('click', ()=>{
      switches.forEach((elem) => {
        elem.classList.remove('active');
      });
      elem.classList.add('active');
      const theme = elem.dataset.theme;
      document.documentElement.setAttribute('data-theme', theme);
    })
  });
});